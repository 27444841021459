var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "10" }
            },
            [
              _c("h3", [
                _vm._v(
                  " " + _vm._s(_vm.$t("settings.page.context.title")) + " "
                )
              ]),
              _c(
                "b-dropdown",
                {
                  staticClass: "context-menu",
                  attrs: {
                    "toggle-class": "dot-button",
                    "no-caret": "",
                    "no-flip": "",
                    right: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function() {
                        return [
                          _c("b-icon", {
                            attrs: {
                              icon: "three-dots-vertical",
                              "aria-hidden": "true",
                              scale: "1"
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("b-dropdown-item", { on: { click: _vm.copySettings } }, [
                    _vm._v(" " + _vm._s(_vm.$t("dropdowns.copySettings")) + " ")
                  ]),
                  _vm.settingsCopied
                    ? _c(
                        "b-dropdown-item",
                        { on: { click: _vm.pasteSettings } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("dropdowns.pasteSettings")) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("hr", { staticClass: "mt-0" }),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("settings.page.context.desc")) + " ")
            ]),
            _c(
              "div",
              { staticClass: "context-wrapper" },
              _vm._l(_vm.contextData, function(contextObject, contextIndex) {
                return _c(
                  "div",
                  {
                    key: `action-${contextIndex}`,
                    staticClass: "context mb-5 pt-4"
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "12" } }, [
                          _c("div", { staticClass: "wrapper-box" }, [
                            _c(
                              "h3",
                              { staticClass: "text-uppercase" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("actionMapping")(
                                        contextObject.action,
                                        _vm.allActions
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "b-dropdown",
                                  {
                                    attrs: {
                                      "toggle-class": "dot-button px-0 pt-0",
                                      "no-caret": "",
                                      dropup: "",
                                      "no-flip": "",
                                      left: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "button-content",
                                          fn: function() {
                                            return [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "three-dots-vertical",
                                                  "aria-hidden": "true",
                                                  scale: ".8"
                                                }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.removeContext(
                                              contextIndex
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "modals.deleteTeamItem.dropdown"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            contextObject.cases.or.length === 0
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "or-context mb-3" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-block and-wrapper p-3"
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "green-button-transparent border-0 text-uppercase",
                                              on: {
                                                click: function($event) {
                                                  return _vm.showItemModal(
                                                    contextObject.action
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("buttons.create")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              : _c(
                                  "div",
                                  [
                                    _vm._l(contextObject.cases.or, function(
                                      orCase,
                                      orIndex
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: `${contextObject.action}-or-${orIndex}`,
                                          staticClass: "or-context mb-3"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-block and-wrapper p-3"
                                            },
                                            [
                                              _vm._l(orCase.and, function(
                                                andCase,
                                                andIndex
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: `${contextObject.action}-or-${orIndex}-and-${andIndex}`,
                                                    staticClass:
                                                      "and-box box p-1 pl-3 mb-3 text-white"
                                                  },
                                                  [
                                                    andCase[
                                                      Object.keys(andCase)[0]
                                                    ][0].var ===
                                                    "personalized_variable_set_id"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " ..." +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "settings.page.context.condition.if"
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  `settings.page.context.condition.item`
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            " ..." +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "settings.page.context.condition.if"
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  `settings.page.context.condition.source.${contextObject.source}`
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "settings.page.context.condition.value"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "printName"
                                                                )(
                                                                  andCase[
                                                                    Object.keys(
                                                                      andCase
                                                                    )[0]
                                                                  ][0].var,
                                                                  _vm.personalizationValues
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]),
                                                    andCase[
                                                      Object.keys(andCase)[0]
                                                    ][0].var ===
                                                    "personalized_variable_set_id"
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  `settings.page.context.condition.operatorSet.${
                                                                    Object.keys(
                                                                      andCase
                                                                    )[0]
                                                                  }`
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getSetName(
                                                                  andCase[
                                                                    Object.keys(
                                                                      andCase
                                                                    )[0]
                                                                  ][1]
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  `settings.page.context.condition.operator.${
                                                                    Object.keys(
                                                                      andCase
                                                                    )[0]
                                                                  }`
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                andCase[
                                                                  Object.keys(
                                                                    andCase
                                                                  )[0]
                                                                ][1]
                                                              )
                                                            )
                                                          ])
                                                        ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "close",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.removeElement(
                                                              {
                                                                action: contextIndex,
                                                                or: orIndex,
                                                                and: andIndex
                                                              }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          staticClass:
                                                            "top--1 mr-1",
                                                          staticStyle: {
                                                            color: "#000"
                                                          },
                                                          attrs: {
                                                            icon: "x",
                                                            scale: "1.5",
                                                            "aria-hidden":
                                                              "true"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              }),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "green-button-transparent border-0 text-uppercase",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.showItemModal(
                                                        contextObject.action,
                                                        orIndex
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("buttons.and")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    }),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "green-button-transparent border-0 text-uppercase",
                                        on: {
                                          click: function($event) {
                                            return _vm.showItemModal(
                                              contextObject.action
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("buttons.or")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  2
                                )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              0
            ),
            _vm.showContextCreateButton
              ? _c(
                  "div",
                  { staticClass: "new-value-box px-3 py-3 mb-4" },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "8" } }, [
                          _c("h3", { staticClass: "mb-0" }, [
                            _vm._v(
                              _vm._s(_vm.$t("settings.page.context.newTitle"))
                            )
                          ]),
                          _c("p", {
                            staticClass: "mb-0",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("settings.page.context.newDesc")
                              )
                            }
                          })
                        ]),
                        _c(
                          "b-col",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            attrs: { cols: "4" }
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "btn px-4 green-button-transparent border-0 btn-secondary text-center",
                                on: {
                                  click: function($event) {
                                    _vm.showNewContextModal = true
                                  }
                                }
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "mr-2 top--3",
                                  attrs: {
                                    icon: "plus-circle-fill",
                                    "aria-hidden": "true",
                                    scale: "1"
                                  }
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("settings.page.context.newButton")
                                    ) +
                                    " "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.showNewContextModal
              ? _c(
                  "div",
                  [
                    _c("modal-create-new-context", {
                      attrs: { "available-actions": _vm.availableActions },
                      on: {
                        "create-context": _vm.createContext,
                        "cancel-modal": function($event) {
                          _vm.showNewContextModal = false
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.showContextItemModal
              ? _c(
                  "div",
                  [
                    _c("modal-create-context-item", {
                      attrs: {
                        "personalization-values": _vm.personalizationValues,
                        "personalization-set-data": _vm.personalizationSetData,
                        action: _vm.selectedAction,
                        "or-index": _vm.selectedOrIndex
                      },
                      on: {
                        "create-context": _vm.createContextItem,
                        "cancel-modal": function($event) {
                          _vm.showContextItemModal = false
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }