<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10" class="d-flex align-items-center">
        <h3>
          {{ $t('settings.page.context.title') }}
        </h3>
        <b-dropdown
          class="context-menu"
          toggle-class="dot-button"
          no-caret
          no-flip
          right
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1"></b-icon>
          </template>
          <b-dropdown-item @click="copySettings">
            {{ $t('dropdowns.copySettings') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="settingsCopied"
            @click="pasteSettings"
          >
            {{ $t('dropdowns.pasteSettings') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="10">
        <hr class="mt-0">
        <p class="mb-3">
          {{ $t('settings.page.context.desc') }}
        </p>
        <div class="context-wrapper">
          <div v-for="(contextObject, contextIndex) in contextData"
               :key="`action-${contextIndex}`"
               class="context mb-5 pt-4">
            <b-row>
              <b-col cols="12">
                <div class="wrapper-box">
                  <h3 class="text-uppercase">
                    {{ contextObject.action | actionMapping(allActions)}}
                    <b-dropdown
                      toggle-class="dot-button px-0 pt-0"
                      no-caret
                      dropup
                      no-flip
                      left
                    >
                      <template #button-content>
                        <b-icon icon="three-dots-vertical"
                                aria-hidden="true" scale=".8"
                        ></b-icon>
                      </template>
                      <b-dropdown-item
                        @click="removeContext(contextIndex)"
                      >
                        {{ $t('modals.deleteTeamItem.dropdown') }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </h3>
                  <div v-if="contextObject.cases.or.length === 0">
                    <div class="or-context mb-3">
                      <div class="d-block and-wrapper p-3">
                        <b-button
                          class="green-button-transparent border-0 text-uppercase"
                          @click="showItemModal(contextObject.action)"
                        >
                          {{ $t('buttons.create') }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="or-context mb-3"
                         v-for="(orCase, orIndex) in contextObject.cases.or"
                         :key="`${contextObject.action}-or-${orIndex}`">
                      <div class="d-block and-wrapper p-3">
                        <div v-for="(andCase, andIndex) in orCase.and"
                             :key="`${contextObject.action}-or-${orIndex}-and-${andIndex}`"
                             class="and-box box p-1 pl-3 mb-3 text-white"
                        >
                          <span
                            v-if="andCase[Object.keys(andCase)[0]][0].var
                              === 'personalized_variable_set_id'"
                          >
                          ...{{ $t('settings.page.context.condition.if') }}
                          {{ $t(`settings.page.context.condition.item`) }}
                          </span>
                          <span v-else>
                            ...{{ $t('settings.page.context.condition.if') }}
                          {{ $t(`settings.page.context.condition.source.${contextObject.source}`) }}
                          {{ $t('settings.page.context.condition.value') }}
                          <strong>{{
                              andCase[Object.keys(andCase)[0]][0].var
                                | printName(personalizationValues)
                            }}</strong>
                          </span>
                          <span
                            v-if="andCase[Object.keys(andCase)[0]][0].var
                              === 'personalized_variable_set_id'"
                          >
                          {{
                            $t(
                            `settings.page.context.condition.operatorSet.${Object.keys(andCase)[0]}`
                            )
                          }}
                            <strong>{{ getSetName(andCase[Object.keys(andCase)[0]][1]) }}</strong>
                          </span>
                          <span v-else>
                          {{
                            $t(
                              `settings.page.context.condition.operator.${Object.keys(andCase)[0]}`
                            )
                          }}
                          <strong>{{ andCase[Object.keys(andCase)[0]][1] }}</strong>
                          </span>
                          <div class="close"
                               @click="removeElement(
                       {action: contextIndex, or: orIndex, and: andIndex}
                       )"
                          >
                            <b-icon
                              icon="x"
                              style="color: #000;"
                              scale="1.5"
                              aria-hidden="true"
                              class="top--1 mr-1"
                            ></b-icon>
                          </div>
                        </div>
                        <b-button
                          class="green-button-transparent border-0 text-uppercase"
                          @click="showItemModal(contextObject.action, orIndex)"
                        >
                          {{ $t('buttons.and') }}
                        </b-button>
                      </div>
                    </div>
                    <b-button
                      class="green-button-transparent border-0 text-uppercase"
                      @click="showItemModal(contextObject.action)"
                    >
                      {{ $t('buttons.or') }}
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="new-value-box px-3 py-3 mb-4" v-if="showContextCreateButton">
          <b-row>
            <b-col cols="8">
              <h3 class="mb-0">{{ $t('settings.page.context.newTitle') }}</h3>
              <p class="mb-0" v-html="$t('settings.page.context.newDesc')"></p>
            </b-col>
            <b-col cols="4" class="d-flex align-items-center justify-content-center">
              <b-button
                class="btn px-4 green-button-transparent border-0 btn-secondary text-center"
                @click="showNewContextModal = true"
              >
                <b-icon
                  icon="plus-circle-fill"
                  class="mr-2 top--3"
                  aria-hidden="true"
                  scale="1"
                ></b-icon>
                {{ $t('settings.page.context.newButton') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="showNewContextModal">
          <modal-create-new-context
            :available-actions="availableActions"
            @create-context="createContext"
            @cancel-modal="showNewContextModal = false"
          />
        </div>
        <div v-if="showContextItemModal">
          <modal-create-context-item
            :personalization-values="personalizationValues"
            :personalization-set-data="personalizationSetData"
            :action="selectedAction"
            :or-index="selectedOrIndex"
            @create-context="createContextItem"
            @cancel-modal="showContextItemModal = false"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'PageSettings',
  components: {
    ModalCreateNewContext: () => import('@/components/modals/ModalCreateNewContext.vue'),
    ModalCreateContextItem: () => import('@/components/modals/ModalCreateContextItem.vue'),
  },
  props: {
    contextData: {
      type: Array,
      required: true,
    },
    personalizationData: {
      type: Array,
    },
    personalizationSetData: {
      type: Array,
    },
    personalizationValues: {
      type: Array,
    },
  },
  data() {
    return {
      showNewContextModal: false,
      showContextItemModal: false,
      allActions: [
        { text: this.$t('settings.page.context.allActions.hidePage'), value: 'hidePage', disabled: false },
        { text: this.$t('settings.page.context.allActions.showPage'), value: 'showPage', disabled: true },
        { text: this.$t('settings.page.context.allActions.showElement'), value: 'showElement', disabled: true },
      ],
      selectedAction: null,
      selectedOrIndex: null,
      settingsCopied: false,
    };
  },
  computed: {
    availableActions() {
      const actions = this.contextData.map((context) => context.action);
      return this.allActions.filter((action) => !actions.includes(action.value));
    },
    showContextCreateButton() {
      // show the button if there are available actions
      if (this.availableActions.length > 0) {
        return this.availableActions.filter((action) => !action.disabled).length > 0;
      }
      return false;
    },
  },
  filters: {
    printName(value, personalizationObject) {
      // split value
      const [setId, valueString] = value.split('.');
      // find the valueString inside the personalizationObject
      const setObject = personalizationObject.find((set) => set.setId === Number(setId));
      if (setObject) {
        // find the name of the element
        const itemObject = setObject.values.find((item) => item.id === valueString);
        if (itemObject) return `${setObject.name}:${itemObject.name}`;
      }
      return value;
    },
    actionMapping(value, allActions) {
      const actionName = allActions.find((action) => action.value === value);
      return (actionName) ? actionName.text : value;
    },
  },
  created() {
    if (localStorage.getItem('context_settings')) {
      this.settingsCopied = true;
    }
  },
  methods: {
    createContext(context) {
      this.showNewContextModal = false;
      this.$emit('context-create', context);
    },
    createContextItem(context) {
      this.showContextItemModal = false;
      this.$emit('context-update', context);
    },
    removeElement(element) {
      this.$emit('remove-element', element);
    },
    removeContext(contextIndex) {
      this.$emit('remove-context', contextIndex);
    },
    showItemModal(action, orIndex) {
      this.selectedOrIndex = orIndex;
      this.selectedAction = action;
      this.showContextItemModal = true;
    },
    getSetName(id) {
      const set = this.personalizationSetData.find((element) => element.value === id);
      if (set) return set.text;
      return id;
    },
    copySettings() {
      localStorage.setItem('context_settings', JSON.stringify(this.contextData));
      this.settingsCopied = true;
    },
    pasteSettings() {
      const newSettings = localStorage.getItem('context_settings');
      localStorage.removeItem('context_settings');
      this.settingsCopied = false;
      this.$emit('context-copy', JSON.parse(newSettings));
    },
  },
};
</script>

<style scoped lang="scss">
.and-wrapper {
  border: 1px solid $site-font-color;
}

.box {
  background-color: #53575B;
}
.new-value-box {
  background-color: #53575B;
  p {
    color: #fff;
    font-size: 12px;
  }
}
/deep/ .dot-button {
  background-color: transparent;
  border: none;
}
/deep/ .context-menu .dot-button {
  position: relative;
  top: -5px;
}
.close {
  cursor: pointer;
}
</style>
